<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Move in</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Move in date 
                <v-spacer></v-spacer>
                <v-btn @click="openBookMove" depressed small width="100" color="primary">Pick date</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Inventory
                <v-spacer></v-spacer>
                <v-btn @click="openInventory" depressed color="primary" small width="100">View details</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="primary"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Insurance
                <v-spacer></v-spacer>
                <v-btn @click="openInsurance" depressed color="primary" small width="100">View details</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="primary"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Utilities
                <v-spacer></v-spacer>
                <v-btn @click="openUtilities" depressed color="primary" small width="100">View details</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="primary"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Property hub
                <v-spacer></v-spacer>
                <v-btn @click="openPropertyHub" depressed color="primary" small width="100">View details</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="moveInComplete" width="100" small depressed class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppBookMoveCard v-if="showBookMove" />
      <TenantAppInventoryCard v-if="showInventory" />
      <TenantAppInsuranceCard v-if="showInsurance" />
      <TenantAppUtilitiesCard v-if="showUtilities" />
      <TenantAppPropertyHubCard v-if="showPropertyHub" />
    </v-dialog>
  </div>
</template>

<script>
import TenantAppBookMoveCard from '@/components/tenantApp/movingIn/TenantAppBookMoveCard.vue';
import TenantAppInventoryCard from '@/components/tenantApp/movingIn/TenantAppInventoryCard.vue';
import TenantAppInsuranceCard from '@/components/tenantApp/movingIn/TenantAppInsuranceCard.vue';
import TenantAppUtilitiesCard from '@/components/tenantApp/movingIn/TenantAppUtilitiesCard.vue';
import TenantAppPropertyHubCard from '@/components/tenantApp/movingIn/TenantAppPropertyHubCard.vue';

export default {
  name: 'the-journey-move-in-card',
  components: {
    TenantAppBookMoveCard,
    TenantAppInventoryCard,
    TenantAppInsuranceCard,
    TenantAppUtilitiesCard,
    TenantAppPropertyHubCard
  },
  data() {
    return {
      dialog: false,
      showBookMove: false,
      showInventory: false,
      showInsurance: false,
      showUtilities: false,
      showPropertyHub: false,
    }
  },
  methods: {
    moveInComplete() {
      this.$emit('moveInComplete')
    },
    nullAllViews() {
      this.showBookMove = false
      this.showInventory = false
      this.showInsurance = false
      this.showUtilities = false
      this.showPropertyHub = false
    },
    openBookMove() {
      this.showBookMove = true
      this.dialog = true
    },
    openInventory() {
      this.showInventory = true
      this.dialog = true
    },
    openInsurance() {
      this.showInsurance = true
      this.dialog = true
    },
    openUtilities() {
      this.showUtilities = true
      this.dialog = true
    },
    openPropertyHub() {
      this.showPropertyHub = true
      this.dialog = true
    }
  }
}
</script>

<style>

</style>