<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Renewal offer</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pt-6 mb-n6">
        <span class="text-h6 primary--text">There are <strong>163</strong> days left on your tenancy agreement</span>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="7">
                <span class="mt-1 text-h6">Would you like to renew tenancy?</span>
              </v-col>
              <v-col cols="12" sm="5">
                <v-row>
                  <v-col class="mt-n6 d-flex justify-start" cols="12" sm="8">
                    <v-radio-group
                      v-model="renewal"
                      row
                    >
                      <v-radio
                        label="Yes"
                        value="yes"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="no"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-n12" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="8" sm="7">
                <span class="mt-1 text-h6 d-sm-none">No. of months:</span>
                <span class="mt-1 text-h6 d-none d-sm-flex">Number of months:</span>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  type="number"
                  placeholder="e.g: 12"
                  dense
                  v-model="months"
                  outlined
                  id="months"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Renew</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'renewal-offer-card',
  data() {
    return {
      months: null,
      renewal: 'yes'
    }
  },
}
</script>

<style>

</style>