<template>
  <div>
    <v-card flat>
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Viewing</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex flex-wrap align-center">
                Tuesday 8th Dec 2020, 9:00am
                <v-spacer></v-spacer>
                <v-btn @click="editTime" small text color="primary">
                  <v-icon left>mdi-pencil</v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="orange lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <v-btn depressed block @click="giveFeedback" color="primary">
                  <span>Give feedback</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text>
                <v-row>
                  <v-col class="">
                    <v-btn block depressed @click="makeOffer" color="primary">
                      <span>Make offer</span>
                    </v-btn>
                  </v-col>
                  <v-col class="mt-6 mt-sm-0">
                    <v-btn block depressed color="secondary" outlined>
                      <span>no thank you</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="viewingsComplete" width="100" small depressed class="mt-6" color="primary" :loading="loading">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <BookViewingCard v-if="showBookViewing" />
      <FeedbackCard v-if="showFeedback" />
      <MakeOfferCard v-if="showMakeOffer" />
    </v-dialog>
  </div>
</template>

<script>
import BookViewingCard from '@/components/tenantApp/viewing/BookViewingCard.vue';
import FeedbackCard from '@/components/tenantApp/viewing/FeedbackCard.vue';
import MakeOfferCard from '@/components/tenantApp/viewing/TenancyOfferCard.vue';

export default {
  name: 'the-journey-viewing-card',
  components: {
    BookViewingCard,
    FeedbackCard,
    MakeOfferCard
  },
  data() {
    return {
      dialog: false,
      loading: false,
      showBookViewing: false,
      showFeedback: false,
      showMakeOffer: false,
    }
  },
  methods: {
    viewingsComplete() {
      this.loading = true
      setTimeout( () => {
        this.$emit('viewingsComplete')
        this.loading = false
      }, 1000)
    },
    nullAllViews() {
      this.showBookViewing = false
      this.showFeedback = false
      this.showMakeOffer = false
    },
    editTime() {
      this.showBookViewing = true
      this.dialog = true
    },
    giveFeedback() {
      this.showFeedback = true
      this.dialog = true
    },
    makeOffer() {
      this.showMakeOffer = true
      this.dialog = true
    }
  }
}
</script>

<style>

</style>