<template>
  <div>
    <v-date-picker
      v-model="date1"
      :events="availableTimes"
      event-color="green"
      color="primary"
      full-width
    ></v-date-picker>
  </div>
</template>

<script>
  export default {
    name: 'theDatePicker',
    props: {
      availableTimes: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      arrayEvents: null,
      date1: new Date().toISOString().substr(0, 10),
    }),
    mounted () {
      this.arrayEvents = [...Array(6)].map(() => {
        const day = Math.floor(Math.random() * 30)
        const d = new Date()
        d.setDate(day)
        return d.toISOString().substr(0, 10)
      })
    },


  }
</script>