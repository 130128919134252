<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Financial verification & Credit check</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="mb-n6">
        <v-tabs v-model="tab">
          <v-tab>Open banking</v-tab>
          <v-tab>Credit check</v-tab>
          <v-tab-item>
            <v-card flat class="py-8 d-flex flex-column">
              <p class="text-body-1">To speed up the referencing process you can help by giving permission to share your financial information vai open banking.  We are able to collect 6 months bank statement to validate your employment and rental payment.</p>
              <v-btn depressed x-large color="primary" class="mt-3 mx-auto rounded-pill">
                <span class="white--text custom-transform-class text-none">Confirm financial position</span>
              </v-btn>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="py-8 d-flex flex-column">
              <p class="text-body-1">If you do not wish to connect the open banking we can carry out a credit check.  The open banking connection is free and a credit check will cost £2.99 to process.</p>
              <p class="text-body-1 font-weight-bold mx-auto mt-3">Please confirm if you wish proceed with a credit check</p>
              <v-btn depressed width="200" x-large color="primary" class="mt-3 mx-auto rounded-pill">
                <!-- <v-icon color="secondary" left>mdi-download</v-icon> -->
                <span class="white--text custom-transform-class text-none">Proceed</span>
              </v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs>

      </v-card-text>
      <v-card-actions class="pa-4">
        <!-- Empty but present to allow for correct spacing -->
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'credit-check',
  data() {
    return {
      tab: '',
      documentTypes: [
        'Driving liccense',
        'National ID card',
        'Passport', 
        'Other'
      ]
    }
  },
}
</script>

<style>

</style>