<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Move out</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Moving out notice
                <v-spacer></v-spacer>
                <v-btn @click="openNoticeCard" depressed small width="100" color="primary">Give notice</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Move out date 
                <v-spacer></v-spacer>
                <v-btn depressed small width="100" color="primary">Edit</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppGiveNoticeCard v-if="showNoticeCard" />
    </v-dialog>
  </div>
</template>

<script>
import TenantAppGiveNoticeCard from '@/components/tenantApp/movingOut/TenantAppGiveNoticeCard.vue';

export default {
  name: 'the-journey-renewal-card',
  components: {
    TenantAppGiveNoticeCard
  },
  data() {
    return {
      dialog: false,
      showNoticeCard: false,
      openBanking: true
    }
  },
  methods: {
    nullAllViews() {
      this.showNoticeCard = false
    },
    openNoticeCard() {
      this.showNoticeCard = true
      this.dialog = true
    }
  }
}
</script>

<style scoped>
.manual-payment-div {
  width: 75%;
}
</style>