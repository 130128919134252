<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Renewal</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="orange"
            small
            icon="mdi-timer-sand"
            fill-dot
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <div class="manual-payment-div"> 
                  <p class="my-0">Renewal</p>
                  <span class="text-caption grey--text font-italic">You have 163 days left on your tenancy</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed small
                  width="100"
                  @click="openRenewalDetails"
                >
                View details
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="orange"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <v-btn to="/review/summary" depressed block color="primary">Review agreement</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="renewalComplete" width="100" small depressed class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppRenewalDetailsCard v-if="showRenewalDetails" />
    </v-dialog>
  </div>
</template>

<script>
import TenantAppRenewalDetailsCard from '@/components/tenantApp/renewal/TenantAppRenewalDetailsCard.vue';
export default {
  name: 'the-journey-renewal-card',
  components: {
    TenantAppRenewalDetailsCard
  },
  data() {
    return {
      dialog: false,
      showRenewalDetails: false,
      openBanking: true
    }
  },
  methods: {
    renewalComplete() {
      this.$emit('renewalComplete')
    },
    nullAllViews() {
      this.showRenewalDetails = false
    },
    openRenewalDetails() {
      this.showRenewalDetails = true
      this.dialog = true
    }
  }
}
</script>

<style scoped>
.manual-payment-div {
  width: 75%;
}
</style>