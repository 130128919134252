<template>
  <div class="journey">
    <v-img class="journey-bg" src="../../assets/dashboard.jpg"></v-img>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div class="journey-container pt-2 pb-16">
      <div class="mt-3 mb-n6 d-flex">
        <span class="text-caption grey--text text--darken-3">{{ dateString }}</span>
        <v-spacer></v-spacer>
        <span class="text-caption grey--text text--darken-3">Updated: 12 minutes ago</span>
      </div>

      <UserDetails
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="my-details" 
        v-intersect="onDetailsIntersect"
        @completeMyDetails="myDetailsComplete = true" 
      />
      <RightMoveSearch  
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
      />
      <!-- v-if="myDetailsComplete" -->
      <ViewingCard 
        class="my-12"
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="viewing"
        v-intersect="onViewingIntersect"
        @viewingsComplete="viewingsComplete = true"
      />
      <OfferCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="offer" 
        v-intersect="onOfferIntersect" 
        @offerComplete="offerComplete = true" 
      />
      <ReferencingCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="referencing" 
        v-intersect="onReferenceIntersect" 
        @referencingComplete="referencingComplete = true" 
      />
      <SigningCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="signing" 
        v-intersect="onSigningIntersect" 
        @signingComplete="signingComplete = true" 
      /> 
      <MoveInCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="move-in" 
        v-intersect="onMoveInIntersect" 
        @moveInComplete="moveInComplete = true" 
      />
      <RentCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="rent" 
        v-intersect="onRentIntersect" 
        @payingRentComplete="payingRentComplete = true" 
      />
      <RenewalCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom"  
        id="renewal" 
        v-intersect="onRenewalIntersect" 
        @renewalComplete="renewalComplete = true" 
      />
      <MoveOutCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom"  
        id="move-out" 
        v-intersect="onMoveOutIntersect" 
      /> 

      <!-- Drawer ----------------------------------------- -->
      <v-navigation-drawer
        class="d-none d-md-block mt-16 left-navigation-drawer"
        fixed
        left 
        floating
        width="170"
      >
        <template v-slot:prepend>
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="secondary--text text-end">My tenancy journey</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in cards"
            :key="item.name"
            @click="goTo(item.href)"
            :input-value="item.href === currentHref"
            active-class="primary lighten-1"
          >
            <v-list-item-content>
              <v-list-item-title class="secondary--text text-end">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

    </div>
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import AOS from 'aos'
import 'aos/dist/aos.css'

import UserDetails from '@/components/tenantApp/theJourneyCards/TenantJourneyUserDetails.vue';
import ViewingCard from '@/components/tenantApp/theJourneyCards/TenantJourneyViewingCard.vue';
import OfferCard from '@/components/tenantApp/theJourneyCards/TenantJourneyOfferCard.vue';
import ReferencingCard from '@/components/tenantApp/theJourneyCards/TenantJourneyReferencingCard.vue';
import SigningCard from '@/components/tenantApp/theJourneyCards/TenantJourneySigningCard.vue';
import MoveInCard from '@/components/tenantApp/theJourneyCards/TenantJourneyMoveInCard.vue';
import RentCard from '@/components/tenantApp/theJourneyCards/TenantJourneyPayingRentCard.vue';
import RenewalCard from '@/components/tenantApp/theJourneyCards/TenantJourneyRenewalCard.vue';
import MoveOutCard from '@/components/tenantApp/theJourneyCards/TenantJourneyMoveOutCard.vue';
import RightMoveSearch from '@/components/searches/RightMoveSearch.vue';

export default {
  name: 'alternative-home-page',
  components: {
    UserDetails,
    ViewingCard,
    OfferCard,
    ReferencingCard,
    SigningCard,
    MoveInCard,
    RentCard,
    RenewalCard,
    MoveOutCard,
    RightMoveSearch
  },
  created() {
    AOS.init()
  },
  data() {
    return {
      todaysDate: new Date(),
      overlay: false,
      currentHref: '#offer',

      myDetailsComplete: true,
      viewingsComplete: false,
      offerComplete: false,
      referencingComplete: false,
      signingComplete: false,
      moveInComplete: false,
      payingRentComplete: false,
      renewalComplete: false,
      loading: false,
      cards: [
        { name: 'My details', href: '#my-details' },
        { name: 'Viewing', href: '#viewing' },
        { name: 'Offer', href: '#offer' },
        { name: 'Referencing', href: '#referencing' },
        { name: 'Signing', href: '#signing' },
        { name: 'Move in', href: '#move-in' },
        { name: 'Paying rent', href: '#rent' },
        { name: 'Renewal', href: '#renewal' },
        { name: 'Move out', href: '#move-out' },
      ]
    }
  },
  methods: {
    completeMyDetails() {
      this.loading = true
      setTimeout( () => {
        this.myDetailsComplete = true
        this.loading = false
      },1000)
    },
    onIntersect (entries) {
      if(entries[0].isIntersecting) {
        console.log(entries)
      }
    },
    onDetailsIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#my-details'
      }
    },    
    onViewingIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#viewing'
      }
    },
    onOfferIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#offer'
      }
    },
    onReferenceIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#referencing'
      }
    },
    onSigningIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#signing'
      }
    },
    onMoveInIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#move-in'
      }
    },
    onRentIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#rent'
      }
    },
    onRenewalIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#renewal'
      }
    },
    onMoveOutIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#move-out'
      }
    },
    goTo(href) {
      this.$vuetify.goTo(href, {
        duration: 300,
        offset: 30,
        easing: 'easeInOutCubic',
      })
    }
  },
  computed: {
    dateString() {
     return moment().format('Do MMMM YYYY')
    },
  }
}
</script>

<style scoped>
.journey-bg {
  position: fixed;
  top: 0;
  height: 100%;
  opacity: 0.3;
  filter: blur(3px);
}

.left-navigation-drawer {
  background: transparent;
}

.journey-container {
  margin: 0 1rem;
}

@media only screen and (min-width: 960px) {
  .journey-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1264px) {
  .journey-container {
    width: 45%;
    margin: 0 auto;
  }
}

/* .fade-enter-active, .fade-leave-active {
  transition: all 1s ease-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.router-link-active {
  border-left-color: green;
} */

</style>