<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Give notice</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="py-6">
        <v-row>
          <v-col cols="12" sm="4">
            <span class="mt-1 text-h6">Status</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-btn depressed block @click="notice = !notice" :outlined="!notice" :color="notice ? 'primary' : 'secondary'" class="py-6" >
              <span v-if="!notice" class="">Give notice</span>
              <span v-else class="">Notice confirmed!</span>
            </v-btn>
            <!-- <p class="mt-3 mb-0 text-center caption primary--text">Click the button above to confirm</p> -->
          </v-col>
        </v-row>
        <v-divider v-if="notice" class="my-6"></v-divider>
        <v-row v-if="notice">
          <v-col class="pb-0" cols="12" >
            <v-card flat class="px-0 white"> 
              <v-card-text class="pa-0">
                <v-row class="">
                  <v-col class="" cols="12" sm="6">
                    <DatePicker />
                  </v-col>
                  <v-col class="d-flex flex-column" cols="12" sm="6">
                    <TimePickerDialog />
                    <v-spacer></v-spacer>
                    <div class="d-flex align-center mt-sm-n12 flex-column">
                      <p class="text-h6 text-sm-h5 my-0">11th November 2020</p>
                      <p class="text-h4 text-sm-h3 mt-0 primary--text">16:00</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn depressed class="mb-10" color="primary">
                      <span class="white--text">Confirm</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" width="200">
          <span class="secondary--text custom-transform-class text-none">Save</span>
        </v-btn>
      </v-card-actions> -->
    </v-card> 
  </div>
</template>

<script>
import DatePicker from '@/components/reusableComponents/TheDatePicker.vue';
import TimePickerDialog from '@/components/reusableComponents/TimePickerDialog.vue';

export default {
  name: 'give-notice-card',
  components: {
    DatePicker,
    TimePickerDialog
  },
  data() {
    return {
      notice: false,
      time: '',
    }
  },
}
</script>

<style>

</style>