<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">My Guarantor</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pb-0 mb-n6">
        <v-row>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Guarantor details</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  dense
                  outlined
                  label="Full name"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  dense
                  outlined
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  dense
                  outlined
                  label="Address"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  label="Mobile"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-select
                  label="Status"
                  dense
                  outlined
                  :items="statuses"
                ></v-select>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  label="Annual income"
                  dense
                  outlined
                  prefix="£"
                  id="income"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Save</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'guarantor-card',
  data() {
    return {
      statuses: [
        'Single', 'Married', 'Student', 'Unemployed', 'Self employed', 'other' 
      ],
    }
  },
}
</script>

<style>

</style>