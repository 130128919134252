<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Inventory</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pb-0 mb-n6">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class=" text-h6">Download copy</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-btn depressed block color="primary">
                  <v-icon left dark>mdi-download</v-icon>
                  Download</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-n8" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Comments</span>
              </v-col>
              <v-col class="mt-n3 mt-sm-3" cols="12" sm="8">
                <v-textarea
                  auto-grow
                  outlined
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'inventory-card',
  data() {
    return {
      
    }
  },
}
</script>

<style>

</style>