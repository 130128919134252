<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Signing</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <v-btn to="/review/summary" target="_blank" depressed block color="primary">Review agreement</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Tenant signed
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            :color="depositConfirmed ? 'primary' : 'orange lighten-2'"
            small
            :fill-dot="depositConfirmed"
            :icon="depositConfirmed ? 'mdi-check' : ''"
          >
            <v-card flat outlined>
              <v-card-text class="">
                <v-btn 
                  depressed
                  :loading="loading"
                  v-if="!depositConfirmed" 
                  @click="openConfirmDeposit" 
                  block 
                  color="primary"
                >
                  Confirm deposit
                </v-btn>
                <div v-else class="d-flex align-center">
                  <span>Deposit confirmed</span>
                  <v-spacer></v-spacer>
                  <v-btn depressed small width="100" color="primary">View details</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            :color="rentConfirmed ? 'primary' : 'orange lighten-2'"
            small
            :fill-dot="rentConfirmed"
            :icon="rentConfirmed ? 'mdi-check' : ''"
          >
            <v-card flat outlined>
              <v-card-text class="">
                <v-btn depressed @click="openRentPayments" v-if="!rentConfirmed" block color="primary" :loading="rentLoading">Rent payments</v-btn>
                <div v-else class="d-flex align-center">
                  <span>Rent payments in place</span>
                  <v-spacer></v-spacer>
                  <v-btn depressed small width="100" color="primary">View / Edit</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="landlordSigned"
            color="primary"
            small
            icon="mdi-check"
            fill-dot
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Landlord / Agent signed
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="agreementConfirmed"
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="">
                Agreement confirmed
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="signingComplete" width="100" small depressed class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppConfirmDepositCard v-if="showConfirmDeposit" />
      <TenantAppRentPaymentsCard v-if="showRentPayments" />
    </v-dialog>
  </div>
</template>

<script>
import TenantAppConfirmDepositCard from '@/components/tenantApp/signing/TenantAppConfirmDepositCard.vue';
import TenantAppRentPaymentsCard from '@/components/tenantApp/signing/TenantAppRentPaymentsCard.vue';

export default {
  name: 'the-journey-signing-card',
  components: {
    TenantAppConfirmDepositCard,
    TenantAppRentPaymentsCard
  },
  data() {
    return {
      dialog: false,
      showConfirmDeposit: false,
      showRentPayments: false,

      tenantSigned: false,
      depositConfirmed: false,
      landlordSigned: false,
      rentConfirmed: false,
      agreementConfirmed: false,
      loading: false,
      rentLoading: false
    }
  },
  methods: {
    signingComplete() {
      this.$emit('signingComplete')
    },
    confirmDeposit() {
      this.loading = true
      setTimeout( () => {
        this.depositConfirmed = true
        this.loading = false
      }, 1000)
    },
    confirmRent() {
      this.rentLoading = true
      setTimeout( () => {
        this.rentConfirmed = true
        this.rentLoading = false
      }, 1000)
    },
    nullAllViews() {
      this.showConfirmDeposit = false
      this.showRentPayments = false
    },
    openConfirmDeposit() {
      this.showConfirmDeposit = true
      this.dialog = true
    },
    openRentPayments() {
      this.showRentPayments = true
      this.dialog = true
    }
  }
}
</script>

<style>

</style>