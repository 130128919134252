<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Insurance</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pt-6">
        <span class="text-body-1 font-weight-bold">Your tenant contents insurance is:</span>
        <v-row>
          <v-col cols="12">
            <v-row>
              <!-- <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Details</span>
              </v-col> -->
              <v-col cols="12" class="d-flex justify-center">
                <ul class="text-body-1">
                  <li>
                    <span>Cover until 20th September 2021</span>
                  </li>
                  <li>
                    <span>Excess £50</span>
                  </li>
                  <li>
                    <span>Email <a href="">claims@vanmildert.net</a></span>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'insurance-card',
  data() {
    return {
      
    }
  },
}
</script>

<style>

</style>