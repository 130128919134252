<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Glyn Trott</span>
        <v-spacer></v-spacer>
        <v-btn small text color="white">
          <v-icon left>mdi-pencil</v-icon>
          <span>Edit</span>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
        <div class="d-flex flex-wrap">
          <p class="text-body-1">
            <span class="font-weight-bold">Email: </span>
            <span>glyn.trott@agentos.com</span>
          </p>
          <p class="text-body-1 ml-sm-12">
            <span class="font-weight-bold">Mobile: </span>
            <span>09814-555-55</span>
          </p>
        </div>
        <v-btn @click="completeMyDetails" width="100" small depressed class="mt-6" color="primary" :loading="loading">Save</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'the-journey-user-details',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    completeMyDetails() {
      this.loading = true
      setTimeout( () => {
        this.$emit('completeMyDetails')
        this.loading = false
      }, 1000)
    }
  }
}
</script>

<style>

</style>