<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Paying rent</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center flex-wrap">
                <p class="my-0">Your next payment of <span class="primary--text font-weight-bold">£675</span> due by <span class="primary--text font-weight-bold">03/12/2020</span></p>
                <p class="my-0">Your current account balance is: <span class="primary--text font-weight-bold">£0.00</span></p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="primary"
            small
            icon="mdi-check"
            fill-dot
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Open banking
                <v-spacer></v-spacer>
                <v-btn 
                  width="100"
                  @click="openBanking = !openBanking" 
                  :color="openBanking ? 'primary' : 'red'" small depressed>
                  <span v-if="openBanking">Active</span>
                  <span v-else class="white--text">Inactive</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <div class="manual-payment-div"> 
                  <p class="my-0">Manual payment</p>
                  <span class="text-caption grey--text font-italic">A manual payment is only required if you do not have an active standing order or rent arrears.</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn @click="openManualPayment" width="100" color="primary" small depressed>Pay</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="payingRentComplete" width="100" small depressed class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppManualPaymentCard v-if="showManualPayment" />

    </v-dialog>
  </div>
</template>

<script>
import TenantAppManualPaymentCard from '@/components/tenantApp/payingRent/TenantAppManualPaymentCard.vue';
export default {
  name: 'the-journey-rent-card',
  components: {
    TenantAppManualPaymentCard
  },
  data() {
    return {
      dialog: false,
      showManualPayment: false,
      openBanking: true
    }
  },
  methods: {
    payingRentComplete() {
      this.$emit('payingRentComplete')
    },
    nullAllViews() {
      this.showManualPayment = false
    },
    openManualPayment() {
      this.showManualPayment = true
      this.dialog = true
    }
  }
}
</script>

<style scoped>
.manual-payment-div {
  width: 75%;
}
</style>