<template>
  <div>
    <v-card flat class="my-12">
      <v-card-title class="secondary">
        <v-spacer></v-spacer>
        <span class="text-h5 white--text">Referencing</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            :color="idConfirmed ? 'primary' : 'red lighten-2'"
            :fill-dot="idConfirmed"
            :icon="idConfirmed ? 'mdi-check' : ''"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <v-btn v-if="!idConfirmed" @click="openConfirmId" depressed color="primary" block>Confirm ID</v-btn>
                <div v-else>
                  ID Confirmed 
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text>
                <v-btn @click="openCreditCheck" depressed class="d-sm-none" color="primary" block>Financial & credit check</v-btn>
                <v-btn @click="openCreditCheck" depressed class="d-none d-sm-block" color="primary" block>Financial verification & credit check</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text>
                <v-btn @click="openReferees" depressed color="primary" block>Referees</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="red lighten-2"
            small
          >
            <v-card flat outlined>
              <v-card-text>
                <v-btn @click="openGuarantor" depressed color="primary" block>My guarantor</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="referencingComplete" width="100" small depressed class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <TenantAppConfirmIdCard v-if="showconfirmID" />
      <TenantAppCreditCheckCard v-if="showCreditCheck" />
      <TenantAppRefereesCard v-if="showReferees" />
      <TenantAppGuarantorCard v-if="showGuarantor" />
    </v-dialog>
  </div>
</template>

<script>
import TenantAppConfirmIdCard from '@/components/tenantApp/referencing/TenantAppConfirmIdCard.vue';
import TenantAppCreditCheckCard from '@/components/tenantApp/referencing/TenantAppCreditCheckCard.vue';
import TenantAppRefereesCard from '@/components/tenantApp/referencing/TenantAppRefereesCard.vue';
import TenantAppGuarantorCard from '@/components/tenantApp/referencing/TenantAppGuarantorCard.vue';

export default {
  name: 'the-journey-referencing-card',
  components: {
    TenantAppConfirmIdCard,
    TenantAppCreditCheckCard,
    TenantAppRefereesCard,
    TenantAppGuarantorCard
  },
  data() {
    return {
      dialog: false,
      idConfirmed: false,
      showconfirmID: false,
      showCreditCheck: false,
      showReferees: false,
      showGuarantor: false
    }
  },
  methods: {
    referencingComplete() {
      this.$emit('referencingComplete')
    },
    nullAllViews() {
      this.showconfirmID = false
      this.showCreditCheck = false
      this.showReferees = false
      this.showGuarantor = false
    },
    openConfirmId() {
      this.showconfirmID = true
      this.dialog = true
    },
    openCreditCheck() {
      this.showCreditCheck = true
      this.dialog = true
    },
    openReferees() {
      this.showReferees = true
      this.dialog = true
    },
    openGuarantor() {
      this.showGuarantor = true
      this.dialog = true
    }
  }
}
</script>

<style>

</style>