<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Confirm ID</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="mb-n6">
        <v-tabs v-model="tab">
          <v-tab>
            <span class="d-sm-none">Passport</span>
            <span class="d-none d-sm-flex">RightMove Passport</span>
          </v-tab>
          <v-tab>Confirm ID</v-tab>
          <v-tab-item>
            <v-card flat class="py-12 d-flex justify-center align-center">
              <v-btn depressed x-large color="primary" class="rounded-pill">
                <v-icon color="white" left>mdi-download</v-icon>
                <span class="white--text custom-transform-class text-none">Download Passport</span>
              </v-btn>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-row class="mt-3">
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Upload documents</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-select
                  dense
                  outlined
                  :items="documentTypes"
                ></v-select>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-file-input
                  label="File input"
                  outlined
                  dense
                ></v-file-input>
              </v-col>
              <v-col class="mt-n3 d-flex" cols="12" sm="8" offset-sm="4">
                <div class="ml-auto">
                  <v-chip
                    class="my-2 px-6"
                    close
                    color="orange darken-2"
                    label
                    outlined
                  >
                  <v-icon left small>mdi-file-pdf-outline</v-icon>
                    drivingLicense.pdf
                  </v-chip>
                </div>
              </v-col>
            </v-row>          
          </v-tab-item>
        </v-tabs>

      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed v-if="tab === 1" class="pa-4" color="primary" width="200">
          <span class="white--text">Upload</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'confirm-id',
  data() {
    return {
      tab: '',
      documentTypes: [
        'Driving liccense',
        'National ID card',
        'Passport', 
        'Other'
      ]
    }
  },
}
</script>

<style>

</style>