<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Pay holding fee</span>
        <v-spacer></v-spacer>
        <v-icon v-if="offerPaid" large color="success">mdi-check</v-icon>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pb-0 mb-n6">
        <v-row>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Fee</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  readonly
                  dense
                  v-model="fee"
                  outlined
                  prefix="£"
                  id="fee"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed :loading="loading" @click="payFee" color="primary" width="200">
          <span class="white--text">Pay</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'pay-holding-field-card',
  data() {
    return {
      fee: "100.00",
      loading: false,
      offerPaid: false
    }
  },
  methods: {
    payFee() {
        this.loading = true

        setTimeout(() => {
          this.loading = false
          this.offerPaid = true
          this.$emit('holdingFeePaid')
        }, 2500)
    }
  }
}
</script>

<style>

</style>