<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Rent payments</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pb-0 mb-n6">
        <v-row>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Bank details</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  dense
                  outlined
                  label="Account name"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  dense
                  outlined
                  label="Sort code"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-text-field
                  dense
                  outlined
                  label="Account number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-n12" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Standing order details</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  label="Amount"
                  dense
                  v-model="standingOrderAmount"
                  outlined
                  prefix="£"
                  id="standing-order-amount"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-select
                  label="Freequency"
                  dense
                  outlined
                  :items="frequencies"
                  v-model="frequency"
                ></v-select>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8" offset-sm="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Start date"
                      dense
                      outlined
                      v-model="computedDateFormatted"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-n12" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Open banking standing order</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-select
                  label="Status"
                  dense
                  outlined
                  :items="openBankingStatuses"
                  v-model="openBankingStatus"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Confirm</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'rent-payments',
  data() {
    return {
      standingOrderAmount: null,
      frequency: '',
      frequencies: [
        'Weekly', 'Monthly',  
      ],
      openBankingStatus: '',
      openBankingStatuses: [
        'New',
        'Cancelled',
        'Authorised'
      ],
      date: null,
      menu: false

    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
}
</script>

<style>

</style>