<template>
  <div>
    <v-card id="viewing">
      <v-card-title>
        <span class="text-h5">Book viewing</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="text-h6 mt-0">20 Hunter Street</p>
        <p class="text-body-2 mt-n3">Cardiff, CF24 4HL</p>
        <span class="primary--text">Select data & time</span>
        <v-row class="mt-2">
          <v-col class="pb-0" cols="12">
            <!-- <v-divider class="mb-6"></v-divider> -->
            <v-card flat class="px-0 white"> 
              <v-card-text class="pa-0">
                <v-row class="">
                  <v-col class="mt-n6" cols="12" sm="7">
                    <DatePicker />
                  </v-col>
                  <v-col class="d-flex flex-column mt-n6" cols="12" sm="5">
                    <v-btn 
                      class="mb-5 py-5" 
                      v-for="time in viewings" :key="time" 
                      :outlined="time !== '9:00am'" 
                      color="secondary"
                    >
                      <span>{{ time }}</span>
                    </v-btn>
                    <v-btn disabled class="mt-auto mb-10" color="primary">
                      <span class="secondary--text custom-transform-class text-none">Confirm</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex align-center">
          <span class="primary--text">Your viewings:</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text>
            <v-icon left small>mdi-pencil</v-icon>
            <span class="primary--text custom-transform-class text-none">Edit</span>
          </v-btn>

        </div>
        <v-divider class="my-3"></v-divider>
        <v-row align="center">
          <v-col cols="4">
            <!-- <v-img  height="50" src="../../assets/mock-property-img.jpg"></v-img> -->
            <v-img  height="50" src="../../../assets/mock-property-img.jpg"></v-img>
          </v-col>
          <v-col cols="8">
            <span class="text-body-1">9:00am - Tuesday 8th December</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DatePicker from '@/components/reusableComponents/TheDatePicker.vue';

export default {
  name: 'book-viewing-card',
  components: {
    DatePicker
  },
  data() {
    return {
      viewings: [
        '9:00am',
        '1:00pm',
        '4:00pm'
      ]
    }
  },
}
</script>

<style>

</style>