<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Utilities</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>

              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Notify council tax</span>
              </v-col>
              <v-col class="pt-0 d-flex align-center justify-space-between" cols="12" sm="8">
                <v-checkbox
                  label="Council notified"
                ></v-checkbox>
                <v-btn
                 href="https://www.gov.uk/council-tax-bands"
                 target="_blank"
                 text color="primary">
                  <span class="primary--text custom-transform-class text-none">
                    gov.uk
                  </span>
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">TV license</span>
              </v-col>
              <v-col class="pt-0 d-flex align-center justify-space-between" cols="12" sm="8">
                <v-checkbox
                  label="TV license paid"
                ></v-checkbox>
                <v-btn 
                  href="https://www.tvlicensing.co.uk/"
                  target="_blank"
                  text color="primary">
                  <span class="primary--text custom-transform-class text-none">
                    tvlicensing.co.uk
                  </span>
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Energy supplier</span>
              </v-col>
              <v-col class="d-flex align-center justify-space-between" cols="12" sm="8">
                <v-text-field
                  dense
                  outlined
                  placeholder="Name of supplier"
                  id="energy"
                ></v-text-field>
                <v-btn 
                  href="https://www.uswitch.com/gas-electricity/"
                  target="_blank"
                  class="mt-n6" text color="primary">
                  <span class="primary--text custom-transform-class text-none">
                    uswitch.com
                  </span>
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Broadband supplier</span>
              </v-col>
              <v-col class="d-flex align-center justify-space-between" cols="12" sm="8">
                <v-text-field
                  dense
                  outlined
                  placeholder="Name of supplier"
                  id="broadband"
                ></v-text-field>
                <v-btn 
                  href="https://www.uswitch.com/gas-electricity/"
                  target="_blank"
                  class="mt-n6" text color="primary">
                  <span class="primary--text custom-transform-class text-none">
                    uswitch.com
                  </span>
                </v-btn>
              </v-col> 

            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Save</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'utilities-card',
  data() {
    return {
      
    }
  },
}
</script>

<style>

</style>