<template>
  <div>
    <v-card>
      <v-card-title class="d-flex flex-column align-start pb-sm-0">
        <div>
          <p class="text-h5">Manual payment</p>
        </div>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="py-6 mb-n10">
        <p class="text-body-1 text-sm-h6 mt-3 mb-6"><span class="red--text">Please note:</span> A manual payment is only required if you do not have an active standing order or rent arrears.</p>
        <p class="next-payment text-body-1 text-sm-h6">Your next payment of <span class="primary--text font-weight-bold">£675</span> due by <span class="primary--text font-weight-bold">03/12/2020</span></p>
        <v-row>
          <v-col class="" cols="12">
            <v-row>
              <!-- <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Standing order details</span>
              </v-col> -->
              <v-col cols="12" sm="8">
                <v-text-field
                  label="Amount"
                  dense
                  type="number"
                  v-model.number="standingOrderAmount"
                  outlined
                  prefix="£"
                  id="amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" width="200">
          <span class="white--text">Pay</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'standing-order-card',
  data() {
    return {
      standingOrderAmount: '',
      frequency: '',
      frequencies: [
        'Weekly', 'Monthly' 
      ],
      date: null,
      menu: false,
      rent: ''
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
  
}
</script>

<style>

</style>