<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Property hub</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text>
        <v-row class="py-2">
          <v-col class="py-2 d-flex flex-column flex-sm-row align-center" v-for="item in items" :key="item.name" cols="12">
            <span class="mt-1 text-h6">{{ item.name }}</span>
            <v-divider class="d-none d-sm-block mx-6"></v-divider>
            <div>
              <v-btn
                :href="item.link"
                target="_blank"
                text color="primary"
              >
                <v-icon v-if="item.pdf" left>mdi-file-pdf-outline</v-icon>
                <span v-if="item.pdf" class="primary--text custom-transform-class text-none">
                  PDF File
                </span>
                <span v-else class="primary--text custom-transform-class text-none">
                  {{ item.alias }}
                </span>
              </v-btn>          
            </div>
            <!-- <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">{{ item.name }}</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-btn
                  :href="item.link"
                  target="_blank"
                  text color="primary"
                >
                  <v-icon v-if="item.pdf" left>mdi-file-pdf-outline</v-icon>
                  <span v-if="item.pdf" class="primary--text custom-transform-class text-none">
                    PDF File
                  </span>
                  <span v-else class="primary--text custom-transform-class text-none">
                    {{ item.alias }}
                  </span>
                </v-btn>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'property-hub-card',
  data() {
    return {
      items: [
        {
          name: 'How to rent guide',
          link: 'https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/821379/6.5707_MHCLG_How_to_Rent_v4.pdf',
          pdf: true
        },
        {
          name: 'Electric safety',
          link: 'ElecCert.pdf',
          pdf: true
        },
        {
          name: 'Energy efficiency',
          link: '',
          pdf: true
        },
        {
          name: 'Gas safety',
          link: 'GasCert.pdf',
          pdf: true
        },
        {
          name: 'Appliance manuals',
          link: '',
          pdf: false,
          alias: 'manualsonline.com'
        },
        {
          name: 'Your landlord license',
          link: 'HmoLicense.pdf',
          pdf: true
        },
        {
          name: 'Inventory',
          link: '',
          pdf: true
        }
      ]
    }
  },
}
</script>

<style>

</style>