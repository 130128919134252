<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Confirm deposit</span>
      </v-card-title>
      <v-divider class="mb-n3"></v-divider>
      <v-card-text class="pb-0 mb-n6">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Scheme options</span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-row>
                  <v-col class="mt-n6 d-flex justify-space-around" cols="12">
                    <v-radio-group
                      v-model="scheme"
                      row
                    >
                      <v-radio
                        label="Paid deposit"
                        value="paid"
                      ></v-radio>
                      <v-radio
                        label="Insured deposit"
                        value="insured"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-n12" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="4">
                <span class="mt-1 text-h6">Amount</span>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  dense
                  type="number"
                  v-model.number="amount"
                  outlined
                  prefix="£"
                  id="amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed @click="depositPaid = true" :disabled="depositPaid || amount === null" color="primary" width="200">
          <span v-if="depositPaid" class="white--text">Paid</span>
          <span v-else class="white--text">Pay</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'confirm-deposit',
  data() {
    return {
      scheme: 'paid',
      amount: null,
      depositPaid: false 
    }
  },
}
</script>

<style>

</style>