<template>
  <div>
    <v-card class="pb-3">
      <v-card-title class="secondary">
        <span class="text-h5 white--text">{{ propertyDetails.Address.Address1 }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6 pb-0">
        <v-row no-gutters>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">My status</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-select
                  dense
                  outlined
                  :items="statuses"
                  v-model="myStatus"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">My annual income</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-text-field
                  dense
                  v-model="myIncome"
                  outlined
                  prefix="£"
                  id="myIncome"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Like to move in by:</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="computedDateFormatted"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Children</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-select
                  dense
                  outlined
                  :items="children"
                  v-model="myChildren"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Rent</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-text-field
                  dense
                  v-model="rent"
                  outlined
                  label="Rent"
                  prefix="£"
                  id="rent"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="12">
            <v-row align="center">
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Other</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0 d-flex justify-start" cols="12" sm="8">
                <v-switch inset label="Pets" v-model="pets"></v-switch>
                <v-switch class="ml-12" inset label="Smoker" v-model="smoker"></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-2" cols="12">
            <v-row>
              <v-col class="d-flex justify-start" cols="12" sm="4">
                <span class="mt-1 text-h6">Requirements</span>
              </v-col>
              <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                <v-textarea 
                  dense
                  placeholder="Enter any requirements here..."
                  auto-grow
                  outlined
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn 
          block
          depressed 
          color="primary"
          x-large 
        >
          <span class="white--text">Send offer</span>
        </v-btn>
      </v-card-actions>
    </v-card> 
  </div>
</template>

<script>
export default {
  name: 'tenancyOfferCard',
    props: {
    propertyDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      myIncome: '',
      statuses: [
        'Single', 'Married', 'Student', 'Unemployed', 'Self employed', 'other' 
      ],
      myStatus: '',
      children: [ 'none', 1, 2, 3, 4],
      myChildren: '',
      pets: false,
      smoker: false,
      date: null,
      menu2: false,
      rent: ''
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
  
}
</script>

<style>

</style>