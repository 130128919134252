<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Give feedback</span>
      </v-card-title>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-textarea
          auto-grow
          outlined
          rows="3"
          color="primary"
          name="input-7-4"
          placeholder="Enter any feedback from your viewing."
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn @click="emitFeedback" depressed color="primary" width="200">
          <span class="white--text">Send feedback</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'feedback-card',
  data() {
    return {
      // no data yet
    }
  },
  methods: {
    emitFeedback() {
      this.$emit('feedbackSent')
    }
  }
}
</script>

<style>

</style>